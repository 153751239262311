import React from "react"
import { Row } from "react-bootstrap"
import PropTypes from "prop-types"
import { MdPhone } from "react-icons/md"
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa"
import "./style.scss"

const TopContactBar = ({ title }) => {
  return (
    <>
      <Row style={{ marginRight: 0, marginLeft: 0 }}>
        <div className="w-100 topcontactbar">
          <span className="float-right"><a className={"contactBarHrefTo"} target="_blank"
                                           href={"https://www.facebook.com/profildancecompany/"}><FaFacebookSquare size={28}/></a> <a
            href={"https://www.instagram.com/pro_feel/"} target="_blank" className={"contactBarHrefTo"}><FaInstagram
            size={28}/></a> <a
            href="https://youtube.com/@profildancecompany?si=iJ7QXfqIZA31QYfx" target="_blank"
            className={"contactBarHrefTo"}><FaYoutube
            size={28}/></a></span>
          <span className="float-left"> <MdPhone className="phone-icon-top" size={22}/> <a href="tel:787998001"
                                                                className="contactBarHrefTo">787998001</a></span>
        </div>
      </Row>
    </>
  )
}


TopContactBar.defaultProps = {
  title: `en`,
}

TopContactBar.propTypes = {
  title: PropTypes.string,
}

export default TopContactBar



