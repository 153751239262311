import { Col, Container, Row } from "react-bootstrap"
import React from "react"
import "./style.scss"
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa"
import { MdMail, MdPhone } from "react-icons/md"
import { AiFillBank } from "react-icons/ai"
import logo from "../../images/niepotrzebne/profil1.png"
import Regulamin from "../../files/Regulamin-Zajęć.pdf"

const Footer = () => {
  return (

    <Container fluid className="px-0">
      <Col className="footer-col">
        <footer>
          <Row>
            <Col md={4}>
              <h5 className="text-brown">ZNAJDŹ NAS</h5>
              <Container className="social-icons">
                <a target="_blank" href={"https://www.facebook.com/profildancecompany/"}><FaFacebookF size={28}
                                                                                                color={"black"}/>
                </a><a href={"https://www.instagram.com/pro_feel/"} target="_blank"><FaInstagram size={28}
                                                                                                 color={"black"}/>
              </a><a href="https://youtube.com/@profildancecompany?si=iJ7QXfqIZA31QYfx" target="_blank"><FaYoutube
                size={28} color={"black"}/>
              </a>
              </Container>
              <h5 className="text-brown"><a className="text-brown" href={Regulamin}>REGULAMIN PD</a></h5>
            </Col>
            <Col md={5} className="">
              <h5 className="text-brown">KONTAKT</h5>
              <MdPhone size={22} className={"brown-icon"}/> <a href="tel:787998001"
                                                               className="contactBarHrefTo"> 787998001</a>
              <br/>
              <MdMail size={22} className={"brown-icon"}/> <a href="mailto:kontakt@profildance.com"
                                                              className="contactBarHrefTo"> kontakt@profildance.com</a>
              <br/>
              <br/>
              <span>
                <AiFillBank size={22} className={"brown-icon"}/> Profil Dance Ewa Konecka<br/>
                mBank 70 1140 2004 0000 3302 8036 1839
              </span>
            </Col>
            <Col md={3} className="text-center"><img src={logo}/></Col>
          </Row>
          <Row class={"text-center"}>🍪 Korzystamy z plików cookies do analizy ruchu na stronie za pomocą Google Analytics 🍪</Row>
        </footer>
      </Col>
    </Container>

    // <Container fluid className="px-0 footer-col" noGutters>
    //   {/*<Row >*/}
    //   {/*  <Col className="footer-col">*/}
    //       <footer>
    //         <Row>

    //         </Row>
    //       </footer>
    // </Container>
  )
}

export default Footer
