import React from "react"

import "./style.scss"

import { Document, Page } from "react-pdf"
import { Spinner } from "react-bootstrap"
import { SizeMe } from "react-sizeme"

const CampOffer = () => (
  <div className={"text-center"}>
    <SizeMe>
      {({ size }) => (
        <>
          <h2>Zimowe warsztaty taneczno-artystyczne Ferie 2022</h2>
          <Document file={require("../../images/oferty/Ferie-2022-Dance-Art-Fun-ulotka.pdf")} loading={
            <>
              <div>Ładowanie oferty...</div>
              <Spinner animation="grow" variant="brown">
                <span className="sr-only">Loading...</span>
              </Spinner><Spinner animation="grow" variant="brown">
              <span className="sr-only">Loading...</span>
            </Spinner><Spinner animation="grow" variant="brown">
              <span className="sr-only">Loading...</span>
            </Spinner>
            </>
          }>
            <Page pageNumber={1} width={size.width ? size.width : 1}/>
            <Page pageNumber={2} width={size.width ? size.width : 1}/>
          </Document>
          <br/>
          <h2>Zimowe warsztaty akrobatyczno-taneczne Ferie 2022</h2>
          <Document file={require("../../images/oferty/Ferie-2022-Acro-Dance-Fun-ulotka.pdf")} loading={
            <>
              <div>Ładowanie oferty...</div>
              <Spinner animation="grow" variant="brown">
                <span className="sr-only">Loading...</span>
              </Spinner><Spinner animation="grow" variant="brown">
              <span className="sr-only">Loading...</span>
            </Spinner><Spinner animation="grow" variant="brown">
              <span className="sr-only">Loading...</span>
            </Spinner>
            </>
          }>
            <Page pageNumber={1} width={size.width ? size.width : 1}/>
            <Page pageNumber={2} width={size.width ? size.width : 1}/>
          </Document>
        </>
      )}
    </SizeMe>
  </div>

)

export default CampOffer

