import React, { useState } from "react"
import { Link } from "gatsby"
import { Nav, NavDropdown } from "react-bootstrap"
import "./style.scss"


export default function NavDropdownOnHoverOrClick(props) {
  const [isOpen, updateIsOpen] = useState(false)
  // todo change 992 to 768 annd <Navbar variant="light" expand="lg" to <Navbar variant="light" expand="md"
  // todo and change in styles 992 to 768 annd 993 to 769
  return (
    <span className={"relative-span-for-dropdown"}
          onMouseOver={() => {
            if (window.innerWidth > 992) updateIsOpen(true)
          }}
          onMouseLeave={() => {
            if (window.innerWidth > 992) updateIsOpen(false)
          }}>
                <Link to={props.linkTo} className="link-no-style dropdown-leftside">
                  <Nav.Link as="span" eventKey={props.eventKey} style={{ whiteSpace: "nowrap" }}>
                    {props.name}
                  </Nav.Link>
                </Link>
                <NavDropdown title="" id="nav-dropdown" className={"dropdown-rightside animate slideIn"}
                             onToggle={() => {
                               if (window.innerWidth <= 992)
                                 updateIsOpen(!isOpen)
                               else
                                 updateIsOpen(false)
                             }}
                             show={isOpen}

                >
                  {props.subnavs.map((value, index) => {
                    return <NavDropdown.Item key={index} href={value[0]}>{value[1]}</NavDropdown.Item>
                  })}
                </NavDropdown>
    </span>

  )
}
