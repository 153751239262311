/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from "react"
import "./style.scss"
import { graphql, StaticQuery } from "gatsby"

import { Container, Toast } from "react-bootstrap"
import Navbar from "../navbar"
import TopContactBar from "../topcontactbar"
import Footer from "../footer"
import MessengerCustomerChat from "react-messenger-customer-chat"
import Cookies from "./cookies"

const Layout = ({ children, pageInfo }) => {
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
        <>
          <Container fluid className="px-0 mb-3 main">
            <TopContactBar title={data.site.siteMetadata.title}/>
            <Navbar pageInfo={pageInfo}/>
            <main className="mt-0 ">{children}</main>
          </Container>
          <MessengerCustomerChat
            pageId="377784432372121"
            appId=""
            themeColor="#d4a88c"
            version="6.0"
            language="pl_PL"
            greetingDialogDisplay="fade"
            greetingDialogDelay={30}
            autoLogAppEvents={false}
            minimized={true}
            loggedInGreeting="Cześć! Jak mogę Ci pomóc?"
            loggedOutGreeting="Cześć! Jak mogę Ci pomóc?"
          />
          <Footer/>
        </>
      )}
    />
  )
}

export default Layout
