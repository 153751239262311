import React from "react"
import { Button, Image } from "react-bootstrap"

import "./style.scss"
import { FaFacebookF } from "react-icons/fa"


const WorkshopsOffer = () => (
  <div className={"text-justify"}>
    <p><b>Sunday workhops</b> to organizowane przez nas cyklicznie (raz w miesiącu w wybraną niedzielę)
      spotkania
      ze wspaniałymi gośćmi z tanecznego świata – profesjonalnymi tancerzami, cenionymi choreografami i wybitnymi
      instruktorami. Warsztaty skierowane są do uczestników naszych grup Profilowych oraz wszystkich naszych tanecznych
      znajomych i chętnych z zewnątrz w wieku 13+ lat. Zajęcia prowadzone dla tancerzy na poziomie
      średniozaawansowanym/ zaawansowanym. Nasze warsztaty wyróżnia przyjazna, kameralna atmosfera i wysoki poziom
      zajęć.</p><p>
    Każde spotkanie to inny styl i inny instruktor. Pracowaliśmy już m.in. z Anitą Sawicką, Patrykiem Zamojskim, Cuki,
    Elizą
    Kindziuk, Bartkiem Woszczyńskim, Kasią Zakrzewską, Kamilą Kwietniewską, Marią Ziółkowską, Olgą Zabłozką, Markiem
    Kurkiem. Tańcowaliśmy w stylach takich jak: contemporary, commercial dance, hip-hop, dancehall, modern jazz,
    ladies
    style, broadway jazz. Warsztaty to świetna okazja do rozwoju, poszerzenia swojej tanecznej wszechstronności,
    odkrywania
    nowych inspiracji i świetnej zabawy.</p>

    <Image src={require("../../images/oferty/BlackMinimalistMarketingProposal(3)_optimized.png")} width={"100%"}
           rounded/>
    <p>Wszystkie szczegółowe informacje dotyczące warsztatów udostępniamy na naszym Facebooku w postaci wydarzeń.</p>
    <p className={"text-center"}><Button href="https://www.facebook.com/profildancecompany/"
                                         variant={"brown"}><FaFacebookF/>{" "}Profil Dance</Button> Zapraszamy!</p>
  </div>
)

export default WorkshopsOffer

